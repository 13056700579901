import { List, ListItem } from '@interstate/components/List';
import { RecentScan } from 'store/slice/recentScans/recentScans.types';
import RecentScanItem from './RecentScan';
import 'reusableComponents/recentScans/RecentScan.css';
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import { STRING_CONSTANTS } from 'constants/constants';

interface RecentScans {
  recentScansList: RecentScan[] | undefined;
  searchVehicle: Function;
}

const RecentScansList: React.FC<RecentScans> = ({
  recentScansList,
  searchVehicle
}) => {
  const recentScans = recentScansList ?? [];
  return (
    <Grid>
      <Grid className='recent-text-grid-container'>
        <Typography variant='h6' className='recent-text'>
          {STRING_CONSTANTS.RECENTS}
        </Typography>
      </Grid>
      <List square className='recent-scans-list'>
        {recentScans.map((recentScansItem, index) => (
          <ListItem
            data-testid={`list-item-key-${index + 1}`}
            key={`list-item-key-${index + 1}`}
          >
            <RecentScanItem
              recentScanItem={recentScansItem}
              searchVehicle={searchVehicle}
            />
          </ListItem>
        ))}
      </List>
    </Grid>
  );
};

export default RecentScansList;
